import axios from 'axios';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Loader from '../../../../hospital/components/Loader';
import { PermissionContext } from '../../../../../../context/admin/PermissionContext';

const ModetatorsPermissionsTrack = () => {
  const { permissions } = useContext(PermissionContext);
  
  // Roles State
  const [roles, setRoles] = useState([]);
  
  // Get Token From Cookies And Store It
  const token = Cookies.get('adminToken');
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);

  // Error | Success | Loading Msg
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorSave, setErrorSave] = useState(null);
  const [success, setSuccessSave] = useState(null);
  
  // State for Selected Permissions
  const [selectedPermissions, setSelectedPermissions] = useState({}); // Role ID mapped to selected permission IDs

  // Fetch Roles Using Call Back
  const fetchRoles = useCallback(async () => {
    setError('');
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}roles/track`, {
        headers: {
          Accept: '*/*',
          Authorization: `Bearer ${token}`,
        },
      });
      setRoles(res.data.data);
    } catch (err) {
      if (err?.response?.status === 403) {
        setError('ليس لديك صلاحية الوصول للأدوار.');
      } else {
        setError('حدث خطأ أثناء جلب البيانات.');
      }
    } finally {
      setLoading(false);
    }
  }, [token]);

  // Fetch Roles in Effect
  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  // Fetch Role Permissions when Accordion is opened
  const fetchRolePermissions = async (roleId) => {
    setLoading(true);
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}roles/${roleId}`, {
        headers: {
          Accept: '*/*',
          Authorization: `Bearer ${token}`,
        },
      });
      
      const rolePermissions = res.data.data.permissions.map((perm) => perm.permissionId); 
      setSelectedPermissions((prev) => ({
        ...prev,
        [roleId]: [...rolePermissions],
      }));
      
    } catch (err) {
      setError('حدث خطأ أثناء جلب صلاحيات الدور.');
    } finally {
      setLoading(false);
    }
  };

  // Toggle Accordion and Fetch Permissions
  const toggleAccordion = (index, roleId) => {
    if (openAccordionIndex === index) {
      setOpenAccordionIndex(null);
    } else {
      setOpenAccordionIndex(index);
      if (!selectedPermissions[roleId]) {
        // Fetch permissions only if not already fetched for this role
        fetchRolePermissions(roleId);
      }
    }
  };

  // Handle Checkbox Toggle
  const handleCheckboxChange = (roleId, permissionId) => {
    setSuccessSave('');
    setErrorSave('');
    setSelectedPermissions((prev) => {
      const rolePermissions = prev[roleId] || [];
      if (rolePermissions.includes(permissionId)) {
        return {
          ...prev,
          [roleId]: rolePermissions.filter((id) => id !== permissionId),
        };
      } else {
        return {
          ...prev,
          [roleId]: [...rolePermissions, permissionId],
        };
      }
    });
  };

  // Handle Form Submission
  const handleModeratorsPermissions = async (roleId) => {
    setSuccessSave('');
    setErrorSave('');
    const permissionsIds = selectedPermissions[roleId] || [];
    
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}roles/permissions`,
        {
          roleId,
          permissionsIds,
        },
        {
          headers: {
            'Accept': '*/*',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      setErrorSave('');
      setSuccessSave('تم حفظ الصلاحيات بنجاح');
    } catch (err) {
      if (err?.response?.status === 403) {
        setErrorSave('ليس لديك صلاحية تعيين الصلاحيات.');
      } else {
        setErrorSave('حدث خطأ أثناء تعيين الصلاحيات.');
      }
      setSuccessSave('');
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className='my-4'>
      <p className='text-3xl my-4 avenir-heavy text-black setting-heading'>صلاحيات المشرفين</p>
      {error && <p className='text-red-500'>{error}</p>}
      <div className='border-[1px] border-[--main-bg-color-btn] rounded-lg shadow-md'>
        {roles.map((role, outerIndex) => (
          <div key={role.id} className='accordion border-b-[1px] border-[--main-bg-color-btn] text-black'>
            <button
              className='accordion-header flex justify-between items-center w-full p-4 text-left focus:outline-none'
              onClick={() => toggleAccordion(outerIndex, role.id)}
            >
              <p>{role.name}</p>
              <svg
                className={`w-6 h-6 transform transition-transform duration-200 ${openAccordionIndex === outerIndex ? 'rotate-180' : ''}`}
                viewBox='0 0 20 20'
              >
                <path d='M6 8l4 4 4-4H6z' />
              </svg>
            </button>

            {openAccordionIndex === outerIndex && (
              <div className='accordion-body p-4'>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleModeratorsPermissions(role.id); // Submit selected permissions for this role
                  }}
                >
                  {permissions && Object.keys(permissions).length > 0 ? (
                    Object.entries(permissions).map(([key, values], innerIndex) => (
                      <ul key={innerIndex} className='list-none'>
                        <li key={innerIndex} className='p-2'>
                          <div className='flex flex-row justify-between items-center'>
                            <p className='text-[16px] text-[--main-color-green]'>{key || 'N/A'}</p>
                          </div>
                          {values.map((item) => (
                            <table className='table border-[1px] w-full border-[--main-color-green]' key={item.permissionId}>
                              <tbody>
                                <tr className='py-2 px-4 flex items-center justify-between border-none'>
                                  <td>{item.name}</td>
                                  <td>
                                    <input
                                      value={item.id}
                                      className='checkbox w-[20px] h-[20px] border-2 border-[var(--main-color-orange)!important] [--chkbg:var(--main-color-orange)!important] [--chkfg:black!important] checked:border-[orange!important] rounded-[0!important]'
                                      type='checkbox'
                                      checked={selectedPermissions[role.id]?.includes(item.id) || false}
                                      onChange={() => handleCheckboxChange(role.id, item.id)}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ))}
                        </li>
                      </ul>
                    ))
                  ) : (
                    <p className='text-[--main-color-green]'>لا توجد بيانات لعرضها.</p>
                  )}
                  <button
                    type='submit'
                    className='btn py-1 px-6 rounded-[30px] disabled:bg-opacity-95 disabled:bg-[--main-color-orange] disabled:text-black bg-[--main-color-orange] hover:bg-[--main-color-orange-dark] text-black my-2 mx-auto border-none'
                    disabled={!selectedPermissions[role.id] || selectedPermissions[role.id].length === 0} // تحقق إذا لم يتم اختيار صلاحيات
                  >
                    حفظ
                  </button>
                  {((!selectedPermissions[role.id] || selectedPermissions[role.id].length === 0) && !success) && <p className='text-[--main-color-green]'>قم بتحديد صلاحيات قبل الحفظ.</p>}
                  {success && <p className='text-green-500'>{success}</p>}
                  {errorSave && <p className='text-red-500'>{errorSave}</p>}
                </form>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ModetatorsPermissionsTrack;
