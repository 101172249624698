import { Link } from "react-router-dom";

function ClinicsInfo() {
  return (
    <div className="text-center hidden md:block">
      <p className="avenir-heavy text-white text-xl my-3">العيادات الخارجية</p>
      <div className="grid grid-cols-2 avenir-book text-white">
        <ul className="flex justify-start items-center gap-3 flex-col text-sm">
          <Link to={"/hospitals?specialtyId=4"} component="li">
            الطب الباطني
          </Link>
          <Link to={"/hospitals?specialtyId=3"} component="li">
            العيون
          </Link>
        </ul>
        <ul className="flex-box-center gap-3 flex-col text-sm">
          <Link to={"/hospitals?specialtyId=1"} component="li">
            جراحة القلب
          </Link>
          <Link to={"/hospitals?specialtyId=2"} component="li">
            نساء و الولادة
          </Link>
          <Link to={"/hospitals?specialtyId=5"} component="li">
            المخ والاعصاب
          </Link>
        </ul>
      </div>
    </div>
  );
}

export default ClinicsInfo;
