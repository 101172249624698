import React from 'react'
import error_unauth from '@/assets/video/error401.gif'
export default function ErrorUnauthorized() {
  return (
    <div>
          <div className='w-full bg-white h-screen text-center'>
        <p className='py-10 text-[--main-color-green] text-3xl avenir-heavy'>يجب عليك تسجيل الدخول أولا!</p>
      <img className='m-auto' src={error_unauth}  alt='Error not Found'/>
    </div>
    </div>
  )
}
