import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const CitiesContext = createContext();

const CitiesData = ({children}) =>{
    const [cities, setCities] = useState([]);
    const [errorCities, setErrorCities] = useState(null)
    useEffect(()=>{
        const initialData = async() =>{
            try{
                const response = await axios.get(`${process.env.REACT_APP_API_URL}cities`);
                
                setCities(response.data.data);
                } catch(err){
                    setErrorCities('خطأ في جلب البيانات');
                }
        }
        initialData();
    },[]);

    return(
        <CitiesContext.Provider value={
            {
            cities,
            errorCities
        }
        }>{children}</CitiesContext.Provider>
    )
}

export default CitiesData