import logo_img from "../assets/imgs/logo_white.png";
import { Link } from "react-router-dom";

function LogoFooter() {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 max-h-[200px] md:grid-rows-1 grid-rows-2 w-fit gap-3 items-center justify-center text-center content-start m-auto">
      <Link to={"/"}>
        <img className="w-[100px]" src={logo_img} alt="logo_white" />
      </Link>
      <Link to={"/"} className="text-white font-bold text-2xl">
        تراك
      </Link>
    </div>
  );
}

export default LogoFooter;
