import React, { useCallback, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Loader from '../../../../hospital/components/Loader';
import axios from 'axios';
import edit_icon from '../../../../assets/icons/edit_btn.svg';
import { useForm } from 'react-hook-form';

const ModalEditPermissions = ({ success, error, handleSubmit, handleEditPermissionClients, register, permission }) => (
  <dialog id="my_modal_3" className="modal w-[90%!important] p-3 mx-6">
    <div className="modal-box w-[inherit] overflow-y-visible rounded-md bg-[--main-color-green] text-white ">
      <form method="dialog">
        <button className="btn btn-sm btn-circle btn-ghost absolute bg-[--main-color-error] hover:bg-red-600 top-[-18px] left-[-12px] font-black avenir-book">✕</button>
      </form>
      <p className="font-bold text-lg avenir-book mb-2">تعديل صلاحية {permission?.name}</p>
      <form className='my-3 flex flex-col gap-4 justify-center items-center m-auto' onSubmit={handleSubmit(handleEditPermissionClients)} >
        <label className='form-control w-full justify-center items-center'>
          <div>
            <span className='block avenir-heavy text-white mt-3 mb-1 text-start'>مستشفى</span>
          </div>
          <select
            className="select select-bordered avenir-book bg-white text-gray-500 w-full max-w-xs"
            {...register("hospitalPermission")}
          >
            <option value={'true'}>نعم</option>
            <option value={'false'}>لا</option>
          </select>
        </label>
        <label className='form-control w-full justify-center items-center'>
          <div>
            <span className='block avenir-heavy text-white mt-3 mb-1 text-start'>شركة تأمين</span>
          </div>
          <select
            className="select select-bordered avenir-book bg-white text-gray-500 w-full max-w-xs"
            {...register("insurancePermission")}
          >
            <option value={'true'}>نعم</option>
            <option value={'false'}>لا</option>
          </select>
        </label>
        <button className='py-3 px-5 bg-[--main-bg-color-btn] text-black rounded-md' type='submit'>تعديل</button>
      </form>
      {success && <p className='text-white my-3'>{success}</p>}
      {error && <p className='text-red-500 my-3'>{error}</p>}
    </div>
  </dialog>
);

const TrackClientPermissions = () => {
  // Permissions State
  const [clientPermissions, setClientPermissions] = useState([]);
  const [selectedPermission, setSelectedPermissions] = useState(null);
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const { register, handleSubmit, reset, setValue } = useForm();

  // Loading / Error Msg
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Fetch Track Admin Permissions Using UseCallback
  const fetchTrackAdminPermissions = useCallback(async () => {
    setLoading(true);
    setError('');
    try {
      const token = Cookies.get('adminToken');
      const res = await axios.get(`${process.env.REACT_APP_API_URL}permissions/clients`, {
        headers: {
          'Accept': '*/*',
          'Authorization': `Bearer ${token}`
        }
      });
      setClientPermissions(res.data.data);
    } catch (err) {
      if(err?.response?.status === 403){
        setError('ليس لديك صلاحية الوصول لهذا العنصر.')
      }else{
      setError('خطأ في جلب البيانات');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  // Fetch Track Admin Permissions Using Effect
  useEffect(() => {
    fetchTrackAdminPermissions();
  }, [fetchTrackAdminPermissions]);

  useEffect(() => {
    if (selectedPermission) {
      setValue('hospitalPermission', selectedPermission.hospitalpermission);
      setValue('insurancePermission', selectedPermission.insurancepermission);
    }
  }, [selectedPermission, setValue]);

  // Handle Show Edit Permissions Modal
  const handleShowModalEditPermissions = useCallback((permission) => {
    setSelectedPermissions(permission);
    setSuccess('');
    setError('');
    reset({
      hospitalPermission: permission.hospitalpermission,
      insurancePermission: permission.insurancepermission,
    });
    
    document.getElementById('my_modal_3').showModal();
  }, [reset]);

  // Handle Edit Clients Permissions As Track Admin
  const handleEditPermissionClients = async (formData) => {
    const updatedData = {
      permissionsIds: [selectedPermission.id], // Ensure this is an array of the selected permission's ID
      hospitalPermission: formData.hospitalPermission === 'true' || formData.hospitalPermission === true,
      insurancePermission: formData.insurancePermission === 'true' || formData.insurancePermission === true,
    };
    
    try {
      const token = Cookies.get('adminToken');
      await axios.patch(`${process.env.REACT_APP_API_URL}permissions`, updatedData, {
        headers: {
          'Accept': '*/*',
          'Authorization': `Bearer ${token}`
        }
      });
      setError('');
      setSuccess('تم تعديل الصلاحية بنجاح');
      setTimeout(()=>{
        fetchTrackAdminPermissions()
      },2000)
    } catch (err) {
      if(err?.response?.status === 403){
        setError('ليس لديك صلاحية تعديل الأعضاء.')
      }else{
        setError('حدث خطأ أثناء تعديل الصلاحية');
      }
      setSuccess('');
    }
  };

  // Loading
  if (loading) {
    return <Loader />;
  }

  return (
    <div className='px-4 my-4 w-full'>
      <p className='text-3xl my-4 avenir-heavy text-black setting-heading'>صلاحيات العملاء</p>

      {error ? <p className='text-red-500'>{error}</p> : (
      (clientPermissions && Object.keys(clientPermissions).length > 0  )? (
        <div className='border-[1px] border-[--main-bg-color-btn] rounded-lg shadow-md'>
          {Object.entries(clientPermissions).map(([key, values], outerIndex) => (
            <div key={outerIndex} className='accordion border-b-[1px] border-[--main-bg-color-btn] text-black'>
              <button className="accordion-header flex justify-between items-center w-full p-4 text-left focus:outline-none" onClick={() => setOpenAccordionIndex(openAccordionIndex === outerIndex ? null : outerIndex)}>
                <p className='text-black text-[20px]'>{key}</p>
                <svg className={`w-6 h-6 transform transition-transform duration-200 ${openAccordionIndex === outerIndex ? 'rotate-180' : ''}`} viewBox="0 0 20 20">
                  <path d="M6 8l4 4 4-4H6z" />
                </svg>
              </button>
              {openAccordionIndex === outerIndex && values.map((item, index) => (
                <ul className="accordion-body p-4 list-none" key={index}>
                  <div className='flex flex-row justify-between items-center'>
                  <p className='p-2 text-[16px] text-[--main-color-green]'>{item.name || 'N/A'}</p>
                  <li className='cursor-pointer' style={{ textAlign: '-webkit-center' }} onClick={() => handleShowModalEditPermissions(item)}>
                    <img className='w-4 h-4' src={edit_icon} alt='edit-icon' />
                  </li>
                  </div>
                  <table className='table border-[1px] w-full border-[--main-color-green]'>
                    <tbody>
                    <tr className='py-2 px-4 border-[--main-color-green]'>
                      <td>المستشفى</td>
                      <td>{item.hospitalpermission ? 'نعم' : 'لا'}</td>
                      </tr>
                  <tr className='py-2 px-4 border-[--main-color-green]'>
                      <td>شركة التأمين </td>
                      <td>{item.insurancepermission ? 'نعم' : 'لا'}</td>
                      </tr>
                    </tbody>
                  </table>
                </ul>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <p className='text-[--main-color-green]'>لا توجد بيانات لعرضها.</p>
      )
      )}

      <ModalEditPermissions error={error} handleSubmit={handleSubmit} success={success} handleEditPermissionClients={handleEditPermissionClients} permission={selectedPermission} register={register} />
    </div>
  );
};

export default TrackClientPermissions;
