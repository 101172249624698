import React from 'react'
import './loader.css'
export default function Loader() {
  return (
    <div className='m-auto'>
      <div className="wrapper">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="shadow"></div>
    <div className="shadow"></div>
    <div className="shadow"></div>
</div>
    </div>
  )
}
