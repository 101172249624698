import { Link, useNavigate } from "react-router-dom";
import signin_icon from '../assets/icons/singin.svg';
import signup_icon from '../assets/icons/signup.svg';
import Cookies from 'js-cookie';
import SignedUser from "../../profile_user/components/SignedUser";
import { useContext } from "react";
import { GetHospitalAdminData } from "../../admin/hospital/components/hospitalAdminInfo/ContextGetData";
import logout_icon from '../../profile_user/assets/icons/logout_icon.svg'
import avatar_hosptial from '../assets/icons/Hospital.svg'
import avatar_admin from '../assets/icons/admin.png'

function SignList() {
  const {clearHospitalData,hospitalData } = useContext(GetHospitalAdminData)
    const navigate = useNavigate();
    const token = Cookies.get('authToken');
    const userToken = Cookies.get('UserToken');
    const adminToken = Cookies.get('adminToken');
    const logout = () => {
        Cookies.remove('authToken');
        Cookies.remove('userRole');
        Cookies.remove('HospitalId');
        Cookies.remove('adminToken');
        clearHospitalData();
        navigate('/');
    };

    return (
        <div>
            {!token && !adminToken && !userToken ? (
                <ul className="avenir-heavy text-black w-full lg:w-[230px] flex-between-center gap-5 lg:gap-1 flex-col text-5xl lg:text-[18px]">
                    <li className="w-full flex flex-row items-center justify-end gap-[2rem]">
                        <Link className="relative navlinks" to="/signin-hospitals">تسجيل دخول</Link>
                        <img className="w-[75px] lg:w-[25%] h-[50px]" src={signin_icon} alt="signin_icon" />
                    </li>
                    <hr className="w-full bg-black h-[1px]" />
                    <li className="w-full flex flex-row items-center justify-end gap-[1rem]">
                        <Link className="relative navlinks" to="/signup-hospitals">إنشاء حساب جديد</Link>
                        <img className="w-[75px] lg:w-[25%] h-[50px]" src={signup_icon} alt="signup_icon" />
                    </li>
                </ul>
            ) : userToken ? (
                <SignedUser />
            ) : (
                <div className='min-w-[250px] lg:min-w-[300px] text-black avenir-heavy '>
                    <div className='flex items-center flex-row  gap-2'>
                    {
                        hospitalData?(
                            hospitalData.logo ? (
                                <div className='w-auto'>
                                    <img className='max-w-[5rem]  rounded-[50%]' src={hospitalData.logo} alt='hospital logo'/>
                                </div>
                            ) : ( 
                                <div className='bg-[--main-color-green] rounded-[50%] w-auto'>
                                    <img className='py-2 px-2 w-[5rem]' src={avatar_hosptial} alt='hospital default logo'/>
                                </div>
                            )
                        ):(
                            <div className='bg-[--main-color-green] rounded-[50%] w-auto'>
                                    <img className='py-2 px-2 w-[4rem]' src={avatar_admin} alt='admin default'/>
                                </div>
                        )
                    }
                    
                            <div>
        <p className='text-lg'>أهلا, {hospitalData ? hospitalData.arName :'admin'}</p>
        {hospitalData ?
        <Link className='text-[15px] text-[--main-color-green] avenir-book'  to={'/admin/hospital-dashboard'}>عرض لوحة التحكم</Link>
        :(
        <Link className='text-[15px] text-[--main-color-green] avenir-book'  to={'/admin/track-dashboard'}>عرض لوحة التحكم</Link>
        )
    }
        </div>
      </div>
        <hr className="w-full bg-[--main-color-green!important] h-[1.5px] my-3" />
        <div className='my-2'>
            <button className='flex flex-row justify-evenly w-full items-center' onClick={logout}>
                <p className='text-[18px] text-black'>تسجيل الخروج</p>
                <img src={logout_icon} alt='logout_icon'/>
            </button>
        </div>
                </div>
            )}
        </div>
    );
}

export default SignList;
