import React, { createContext, useContext, useState, useRef, useEffect } from 'react';
import { RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../../../firebase/firebase';

const ConfirmationContext = createContext();

export const useConfirmation = () => {
  const context = useContext(ConfirmationContext);
  if (!context) {
    throw new Error('useConfirmation must be used within a ConfirmationProvider');
  }
  return context;
};

export const ConfirmationProvider = ({ children }) => {
  const [confirmationResult, setConfirmationResult] = useState(null);

  return (
    <ConfirmationContext.Provider value={{ confirmationResult, setConfirmationResult }}>
      {children}
    </ConfirmationContext.Provider>
  );
};
