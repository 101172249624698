function Contacts() {
    return ( 
        <div className="text-center">
            <p className="avenir-heavy text-white text-xl my-3">معلومات التواصل</p>
            <ul className="flex-box-center gap-3 flex-col avenir-book text-white">
                <li>
                    <a href='tel:+966245782896'  rel="noreferrer" dir="ltr">
                        +966 245782896
                    </a>
                </li>
                <li>
                    <a href='mailto:info@track.com' rel="noreferrer" target="_blank" >
                        info@track.com
                    </a>
                </li>
                <li>
                    <a href='tel:+966415782456' rel="noreferrer"  dir="ltr">
                        +966 415782456
                    </a>
                </li>
            </ul>
        </div>
     );
}

export default Contacts;